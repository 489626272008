import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import square1 from "../images/icons/square1.svg";
import square2 from "../images/icons/square2.svg";
import logo from "../images/memecoin.png";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Enregistrement des éléments nécessaires de Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Tokenomics = () => {
  const data = {
    labels: ["Pre-sale", "LP", "Snoopers", "Team"],
    datasets: [
      {
        data: [35, 45, 10, 10],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        color: "#fff",
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          const sum = context.chart.data.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          const percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
      },
    },
  };

  return (
    <Container
      id="tokenomics"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <div className="text-center">
        <h2 className="section-title">Catenomics</h2>
      </div>
      <div className="hero-gradient-bg">
        <div className="hero-gradient-bg1">
          <img src={square1} alt="square" />
        </div>
        <Row>
          <Col md={8}>
            <div className="d-flex justify-content-center align-items-center h-100">
              <Row>
                <Col md={3}>
                  <div className="hero-gradient-1">
                    <h4>5%</h4>
                    <p>Tax</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="hero-gradient-1">
                    <h4>100,000,000</h4>
                    <p>Total Supply</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="hero-gradient-1">
                    <h4>LP</h4>
                    <p>Smoked</p>
                  </div>
                </Col>
                <Col md={3}></Col>
              </Row>
            </div>
          </Col>
          <Col md={4}>
            <div className="text-center">
              <img src={logo} alt="logo" width="180px" />
            </div>
          </Col>
        </Row>
        <div className="hero-gradient-bg2">
          <img src={square2} alt="square" />
        </div>
      </div>
      <div className="div-graph">
        <Pie className="graph" data={data} options={options} />
      </div>
    </Container>
  );
};

export default Tokenomics;
