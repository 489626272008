import React from "react";
// import './navigation.css'
import logo from "../images/memecoin3.png";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <div className="w-100">
      {/*<div className="ca">
        <div>CA: 5DhKuX1xTAho9J8YQiYYQXka2VwJH5XVcvmArFttAT9uXh9m</div>
      </div>*/}
      <Navbar expand="lg" variant="dark" className="pt-4">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="snoopcat logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto my-2 my-lg-0">
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#tokenomics">Catenomics</Nav.Link>
              <Nav.Link href="#family">Family</Nav.Link>

              <Nav.Link href="#roadmap">Roadmap</Nav.Link>

              {/* <Nav.Link className='loginLink' as={Link} to="/">Login</Nav.Link> */}
              {/* <iframe class="formlets-iframe" src="https://www.formlets.com/forms/EibrIkdhyqrItSo1/?iframe=true&nofocus=y"
                                    frameborder="0" width="100%"></iframe> */}
              <Nav.Link
                className="launchapp-btn hvr-back-pulse me-3"
                href="#earn-snoop"
              >
                Earn $SNOOP
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;
