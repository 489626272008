import React from "react";
import { Container } from "react-bootstrap";
import telegram from "../images/icons/telegram.svg";
import twitter from "../images/icons/twitter.svg";

const Footer = () => {
  return (
    <div>
      <div className="text-center">
        <Container>
          <div className="d-flex justify-content-center mt-5 mb-3">
            <a href="" target="_blank" rel="noreferrer">
              <div className="circle">
                <img src={telegram} alt="alephshib telegram" />
              </div>
            </a>
            <a
              href="https://x.com/SnoopCat_onBase"
              target="_blank"
              rel="noreferrer"
            >
              <div className="circle">
                <img src={twitter} alt="alephshib twitter" />
              </div>
            </a>
          </div>
          <div className="mb-5">
            <a href="mailto:snoop@snoopcatonbase.com" className="mail">
              snoop@snoopcatonbase.com
            </a>
          </div>
        </Container>
      </div>
      <footer>
        <p className="mb-0">
          Copyright © 2024 Snoop Cat Coin. All Rights Reserved
        </p>
      </footer>
    </div>
  );
};

export default Footer;
