import React, { useState } from "react";

function InfoTooltip() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <h3 className="section-title">
        Tweet $SNOOP Earn $SNOOP{" "}
        <span
          onClick={() => setIsVisible(!isVisible)}
          style={{
            cursor: "pointer",
            background: "none",
            border: "2px solid black", // Ajout d'une bordure solide
            borderRadius: "100%", // Rend la bordure circulaire
            padding: "0.25em 0.4em", // Ajustement pour faire un cercle autour du ?
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1em",
            lineHeight: "1", // Assure que le contenu est centré verticalement
            margin: "0 5px", // Espacement autour du bouton pour qu'il ne touche pas le texte
          }}
        >
          ?
        </span>
      </h3>
      {isVisible && (
        <div
          style={{
            position: "absolute",
            width: "200px",
            border: "1px solid black",
            backgroundColor: "white",
            padding: "10px",
            zIndex: "1",
          }}
        >
          Tweet $SNOOP and earn points. Convert points in $SNOOP token once
          live. 5% of total supply allowed to this. You have to follow us on X.
          1 points = 1 $SNOOP. You'll be rich !
        </div>
      )}
    </div>
  );
}

export default InfoTooltip;
