import React, { useState, useEffect } from "react";
import ConnectWallet from "./ConnectWallet";
import InfoTooltip from "./InfoTooltip";

const EarnPoints = () => {
  const [tweetUrl, setTweetUrl] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [userAddress, setUserAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState("");
  const [points, setPoints] = useState(0);
  const base_url = "https://memecoin-backend-269423e176e5.herokuapp.com/";

  // Fonction appelée par ConnectWallet quand le wallet est connecté
  const handleWalletConnected = (address, balance) => {
    setUserAddress(address);
    setTokenBalance(balance);
    setSubmitEnabled(true);
    // Active le bouton de soumission si le wallet est connecté
  };

  const handleSubmit = async () => {
    if (!tweetUrl) {
      alert("Please enter a tweet URL.");
      return;
    }

    // Structurez la requête en fonction de votre endpoint API
    const requestData = {
      user_address: userAddress,
      tweet_url: tweetUrl,
      token_balance: tokenBalance, // Assurez-vous que c'est le format attendu par votre API
    };

    try {
      const response = await fetch(base_url + "submitTweet/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      // Vérifiez si la réponse du serveur est OK (status 200-299)
      const data = await response.json(); // Convertit la réponse en JSON
      if (!response.ok) {
        alert(`Erreur : ${data.error}`);
        throw new Error("La réponse du serveur n'est pas OK.");
      }
      if (userAddress) {
        fetch(base_url + `getPoints/${userAddress}/`)
          .then((response) => response.json())
          .then((data) => setPoints(data.points))
          .catch((error) =>
            console.error("Erreur lors de la mise à jour des points:", error)
          );
      }
      // Traitez et affichez les données réussies ici
      alert("Succès : Votre tweet a été soumis.");
    } catch (error) {
      // Gestion des erreurs de la requête ou de la réponse
      console.error("Erreur lors de la requête:", error);
      alert("Erreur lors de l'envoi de la requête au serveur.");
    }
  };

  useEffect(() => {
    // Remplacer '/getPoints/address' par votre véritable endpoint
    if (userAddress) {
      fetch(base_url + `getPoints/${userAddress}/`)
        .then((response) => response.json())
        .then((data) => setPoints(data.points))
        .catch((error) =>
          console.error("Erreur lors du chargement des points:", error)
        );
    }
  }, [userAddress]);

  return (
    <div className="about-section earn-div text-center" id="earn-snoop">
      <InfoTooltip></InfoTooltip>
      <p>
        Requirements :{" "}
        <a className="follow" href="https://x.com/SnoopCat_onBase">
          Follow us on X
        </a>{" "}
      </p>
      <p>Info : You can use a new wallet, doesn't matter</p>
      <ConnectWallet onConnected={handleWalletConnected} />
      <div className="submit-tweet">
        <input
          type="text"
          placeholder="Enter Tweet URL"
          value={tweetUrl}
          onChange={(e) => setTweetUrl(e.target.value)}
          disabled={!submitEnabled}
          className="input-tweet"
        />
        <button
          onClick={handleSubmit}
          disabled={!submitEnabled}
          className="button-submit"
        >
          Earn Points
        </button>
      </div>
      {userAddress ? (
        <h3 className="content-section">SNOOP Points: {points}</h3>
      ) : null}
    </div>
  );
};

export default EarnPoints;
