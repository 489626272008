import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import longetivity from "../images/snoopcat2.jpeg";
import banner from "../images/snoopcat.png";
import fun from "../images/snoopcat3.jpeg";
import community from "../images/snoopcat4.jpeg";
// import fun from '../images/fun.jpg'

const Vision = () => {
  return (
    <div id="family" className="about-section">
      <Container>
        <div className="text-center">
          <h3 className="section-title">SNOOP CAT Family</h3>
        </div>
        <Row>
          <Col md={6} className="mb-4">
            <div
              className="section-inner"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div>
                <h4>Uncle steve</h4>
                <p>
                  Uncle Steve's always cool. He ain't always sober, actually,
                  he's never sober. He's the one who taught me everything, how
                  to roll my first blunt, grind my weed, blow smoke rings.
                </p>
                <p>
                  Dude's a real OG. I don't know how he does it, but he always
                  scores the best bud.
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div
              className="token-image"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <img src={banner} alt="alephshib" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            md={6}
            className="order-sm-first order-md-last order-lg-last ms-auto mb-4"
          >
            <div
              className="section-inner"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div>
                <h4>Cousin Oscar</h4>
                <p>
                  Cousin Oscar... how to describe this Mfs, look this face. He's
                  Uncle Steve's kid, so we're stuck dealing with this douchebag.
                  Just like his dad, he smokes everything in sight and always
                  baked. The guy's totally nuts, barks like a dog.
                </p>
                <p>
                  He pisses everyone off with his shit rap, this asshole thinks
                  he's gonna make it in drill.
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div
              className="token-image"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <img src={longetivity} alt="alephshib" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <div
              className="section-inner"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div>
                <h4>Lil bro - Gary </h4>
                <p>
                  Gary, that's the little bro. This dude's a legend, rolls the
                  best blunts you've ever seen in your life. He's hilarious,
                  should've made it in stand-up, but chose to sell cat shit
                  instead. This guy's gonna be rich one day, for sure.
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div
              className="token-image"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <img src={fun} alt="alephshib" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            md={6}
            className="order-sm-first order-md-last order-lg-last ms-auto mb-4"
          >
            <div
              className="section-inner"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div>
                <h4>Big bro - Calvin</h4>
                <p>
                  Ahh, don't mind him looking bit sour. He ran out of rolling
                  papers right then. He's a good bro, always there when there's
                  trouble. This dude hits hard, he does MMA. One time in
                  sparring, he knocked out Catmzat Chimaev. That's why he's got
                  a split lip now. This guy's savage.
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div
              className="token-image"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <img src={community} alt="alephshib" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Vision;
