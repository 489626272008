import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Roadmap = () => {
  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("alephshib-whitepaper.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "alephshib-whitepaper.pdf";
        alink.click();
      });
    });
  };

  return (
    <div id="roadmap" className="about-section dark_bg">
      <Container
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div className="text-center">
          <h3 className="section-title text-white">Roadmap</h3>
        </div>
        <Row>
          <Col md={4} className="mb-4">
            <div
              className="section-inner section-card"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div className="w-100">
                <h4 className="text-white">Phase 1</h4>
                <ul type="disc">
                  <li className="text-white">Launch website</li>
                  <li className="text-white">Distribute 5% of $STICK</li>
                  <li className="text-white">Flood X with $SNOOP</li>
                  <li className="text-white">Lit a stick</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={4} className="mb-4">
            <div
              className="section-inner section-card"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div className="w-100">
                <h4 className="text-white">Phase 2</h4>
                <ul type="circle">
                  <li className="text-white">Presale</li>
                  <li className="text-white">Token launch</li>
                  <li className="text-white">Renounce contract</li>
                  <li className="text-white">Smokes liquidity</li>

                  <li className="text-white">
                    Convert your $STICK against $SNOOP
                  </li>
                  <li className="text-white">Start marketing</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={4} className="mb-4">
            <div
              className="section-inner section-card"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <div className="w-100">
                <h4 className="text-white">Phase 3</h4>
                <ul type="circle">
                  <li className="text-white">Social media expansion</li>

                  <li className="text-white">Ecosystem Collaboration</li>

                  <li className="text-white">MEXC Listing </li>
                  <li className="text-white">Coingecko Listing</li>
                  <li className="text-white">Uncle Steve become sober (nah)</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <div className="whitepaper w-100 pt-5">
          <div className="text-center">
            <h3 className="section-title text-white">Whitepaper</h3>
            <div className="d-flex justify-content-center">
              <a
                href={process.env.PUBLIC_URL + "/whitepaper.pdf"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  style={{ width: 225 }}
                  className="buy-now-btn hvr-back-pulse"
                >
                  Whitepaper
                </button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Roadmap;
