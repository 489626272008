import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <div
      id="about"
      className="about-section text-center"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <Container>
        <div className="content-section">
          <h2 className="section-title">Mission Statement</h2>
          <p>
            Yo SNOOPERS, spread the words by tweeting $SNOOP and earn some
            $STICK which you exchange to Snoop Cat against $SNOOP once live.
          </p>
          <p>
            5% of total supply is allowed to you. 1 $STICK = 1 $SNOOP. Making
            money has never been easier.
          </p>
          <p>
            Connect your wallet above, follow us on X. Tweet $SNOOP and paste
            your tweet link above. You can't earn $STICK more than 1 time every
            2 hours. Don't fade.
          </p>
          <p>The more you spread, the more money you make. LFG SNOOPERS.</p>
        </div>
      </Container>
    </div>
  );
};

export default About;
